<template>
  <div>
    <a-modal
      :visible="visible"
      title="区域选择"
      width="55%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :pagination="false"
        :columns="columns"
        :data-source="tableDate"
        class="region-table"
        rowKey="identifier"
      >
        <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
        <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'RegionSelectModal',
  components: {},
  data() {
    return {
      visible: false,
      selectType: 'checkbox',
      resultCallBack: {
        onSubmit: Function,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableDate: [],
      columns: [
        {
          title: '区域名称',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '区域标识符',
          dataIndex: 'identifier',
          key: 'identifier',
          align: 'center',
        },
        {
          title: '区域服务器地址',
          dataIndex: 'host',
          key: 'host',
          align: 'center',
        },
        {
          title: '区域标签',
          dataIndex: 'tag',
          key: 'tag',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    async listRegions() {
      const params = {
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.region.getRegions(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.listRegions();
    },
    onShowSizeChange(current, pageSize) {
      this.$set(this.pageInfo, 'page', 1);
      this.$set(this.pageInfo, 'size', pageSize);
      this.listRegions();
    },
    async showCreateDialog(callback, selectType) {
      await this.listRegions();
      // 将已选择置于表格前;
      const target = [];
      this.tableDate = this.tableDate.filter((item) => {
        if (!this.selectedRowKeys.includes(item.identifier)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableDate = [...target, ...this.tableDate];
      this.resultCallBack.onSubmit = callback;
      if (selectType) {
        this.selectType = selectType;
      }
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.listRegions();
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      this.resultCallBack.onSubmit(this.selectedRowKeys);
      this.hideDialog();
    },
  },
};
</script>
