<template>
  <div>
    <a-modal
      :visible="visible"
      title="属性选择"
      width="65%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-select
        style="width: 100%"
        v-model="selectedRowKeys"
        allowClear
        mode="multiple"
        showSearch
        :showArrow="false"
        v-if="tableData.length"
        optionFilterProp="label"
        :open="false"
      >
        <a-select-option v-for="property in tableData" :label="property.name" :key="property.id">{{
          property.identifier
        }}</a-select-option>
      </a-select>
      <div class="product-operations">
        <div v-if="productList.length && loadedDeviceTypeId">
          关联的物模型:
          <a-tag color="green" v-for="product in productList" :key="product.productId">{{ product.name }}</a-tag>
        </div>
        <div class="data-filter">
          <a-input v-model="searchKeyword" class="search-keyword" placeholder="请输入属性标识符或属性名称" allow-clear @keyup.enter.native="doSearch"></a-input>
          <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
        </div>
      </div>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: selectType }"
        size="middle"
        :columns="this.$constRepository.property.columns"
        :data-source="tableData"
        class="property-table"
        rowKey="id"
        :pagination="{
          current: pageInfo.page,
        }"
        @change="pageChange"
      >
        <template slot="propertyType" slot-scope="scope">{{
          scope === $constRepository.property.FILTER_OPTION_PROPERTY_NODE_FATHER.value ? '父节点' : '子节点'
        }}</template>
        <template slot="createdTime" slot-scope="scope">{{
          scope ? moment(scope).format('YYYY年MM月DD日') : '未知'
        }}</template>
        <template slot="lastModificationTime" slot-scope="scope">{{
          scope ? moment(scope).format('YYYY年MM月DD日') : '未知'
        }}</template>
        <template slot="action" slot-scope="scope">
          <a-popover trigger="click" title="属性字段" placement="right">
            <template #content>
              <loaded-device-property-card :properties="[scope]" />
            </template>
            <a-button type="link">查看</a-button>
          </a-popover>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import lodash from 'lodash';
import moment from 'moment';
import LoadedDevicePropertyCard from './loadedDevicePropertyCard';

export default {
  name: 'LoadedDevicePropertiesSelector',
  components: { LoadedDevicePropertyCard },
  data() {
    return {
      visible: false,
      selectType: 'radio',
      resultCallBack: {
        onSubmit: Function,
      },
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      selectedRowKeys: [],
      tableData: [],
      originTableData: [],
      productList: [],
      properties: [],
      productSelect: undefined,
      loadedDeviceTypeId: undefined,
      searchKeyword: undefined,
      moment,
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
    },
    sortData(array) {
      // 按属性标识符，父属性优先，属性定义唯一标识符，三元组来排序
      // 根据id分组，子节点使用parentId
      const group = lodash.groupBy(array, (item) => {
        if (item.parentId) {
          return item.parentId;
        }
        return item.id;
      });
      const groupArray = Object.values(group);
      // 根据属性类型升序排序 1:父节点 2:子节点,使父节点在子节点前
      const sortArray = groupArray.map((properties) => lodash.sortBy(properties, 'propertyType'));
      // 扁平化数组
      const flatArray = lodash.flatMapDeep(sortArray);
      // 根据唯一标识符,属性定义唯一标识符排序
      return lodash.sortBy(flatArray, ['identifier', 'encode']);
    },
    async listProperties() {
      const params = {
        loadedDeviceTypeIds: [this.loadedDeviceTypeId],
        unpaged: true,
      };
      const response = await this.$apiManager.product.getPropertiesByLoadedDeviceTypeIds(params);
      this.properties = response.data.elements;
      this.tableData = this.sortData(response.data.elements);
      this.originTableData = lodash.cloneDeep(this.tableData);
    },
    async listProducts() {
      if (this.loadedDeviceTypeId) {
        const params = {
          detail: true,
          unpaged: true,
          loadedDeviceTypeIds: [this.loadedDeviceTypeId],
        };
        const response = await this.$apiManager.product.getProducts(params);
        this.productList = response.data.elements;
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async showCreateDialog(callback) {
      this.listProperties();
      await this.listProducts();
      // 将已选择置于表格前;
      const target = [];
      this.tableData = this.tableData.filter((item) => {
        if (!this.selectedRowKeys.includes(item.id)) {
          return true;
        }
        target.push(item);
        return false;
      });
      this.tableData = [...target, ...this.tableData];
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    showUpdateDialog(pararms, callback) {
      this.productCategoryForm = pararms;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      const keys = this.selectedRowKeys.map((key) => ({ id: key }));
      const params = lodash.intersectionBy(this.properties, keys, 'id');
      this.resultCallBack.onSubmit(this.selectedRowKeys, params);
      this.hideDialog();
    },
    pageChange(value) {
      this.pageInfo.page = value.current;
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.tableData = this.originTableData.filter((item) => {
        const searchKey = this.searchKeyword.toLocaleLowerCase();
        return (
          item.identifier.toLocaleLowerCase().includes(searchKey) || item.name.toLocaleLowerCase().includes(searchKey)
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.popover-content {
  width: 50vw;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.product-operations {
  margin-top: 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 250px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .product-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
.property-table {
  margin-top: 1vw;
}
</style>
