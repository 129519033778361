<template>
  <div>
    <a-form-model class="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-row :gutter="[24, 16]" type="flex" align="bottom">
        <a-col class="w-1/2">
          <a-form-model-item label="负载类型ID" prop="loadedDeviceTypeId">
            <a-input v-model="form.loadedDeviceTypeId" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="负载类型名称" prop="name">
            <a-input v-model="form.name" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="负载类型类别" prop="categories">
            <a-select v-model="form.categories"  mode="multiple">
              <a-select-option v-for="category in $constRepository.loadedDevice.CATEGORY_OPTION" :key="category.value"
                :value="category.value">
                {{ category.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="设备详情页URL" prop="detailPage">
            <a-input v-model="form.detailPage" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="属性" prop="propertyIds">
            <a-input
              :value="`已选择${form.propertyIds.length}个(${form.properties.map((property) => property.identifier)})`"
              @click="handlePropertySelect">
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="发布区域" prop="regionIdentifiers">
            <a-input :value="`已选择${form.regionIdentifiers.length}个（${form.regionIdentifiers.map((region) => region)}）`"
              @click="handleRegionSelect">
              <a-tooltip slot="suffix" title="Extra information">
                <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="图片" prop="images">
            <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="(key) => (tabKey = key)">
              <a-form :model="form.images[tabKey - 1]" layout="vertical">
                <a-row :gutter="[24, 16]" type="flex" align="bottom">
                  <a-col class="w-full">
                    <a-form-model-item label="负载类型实体图" prop="solidImages">
                      <div class="clearfix">
                        <a-upload list-type="picture-card" accept="image/*" :customRequest="solidCustomRequest"
                          :data="form.images[tabKey - 1]" :file-list="form.images[tabKey - 1].solidImages"
                          @preview="handlePreview" :remove="removeSolidImages">
                          <div>
                            <a-icon type="plus" />
                            <div class="ant-upload-text">Upload</div>
                          </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                          <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
                        </a-modal>
                      </div>
                    </a-form-model-item>
                  </a-col>
                  <a-col class="w-full">
                    <a-form-model-item label="负载类型线框图" prop="lineImages">
                      <div class="clearfix">
                        <a-upload list-type="picture-card" accept="image/*" :customRequest="lineCustomRequest"
                          :data="form.images[tabKey - 1]" :file-list="form.images[tabKey - 1].lineImages"
                          @preview="handlePreview" :remove="removeLineImages">
                          <div>
                            <a-icon type="plus" />
                            <div class="ant-upload-text">Upload</div>
                          </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                          <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
                        </a-modal>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-model-item label="属性编辑器" prop="type">
            <vue-json-editor
              v-model="form.properties"
              :showBtns="false"
              :mode="'code'"
              lang="zh"
              style="height: 800px"
            />
          </a-form-model-item>
        </a-col>
        <a-col class="w-full">
          <a-form-item class="group-btn">
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button style="margin-left: 10px" @click="$router.back()">取消</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form-model>
    <loaded-device-properties-selector ref="loadedDevicePropertiesSelector" />
    <region-select-modal ref="regionSelectModal" />
    <upload-progress ref="uploadProgress" />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import UploadProgress from '../../../components/upload-progress/UploadProgress';
import RegionSelectModal from '../region/components/RegionSelectModal';
import LoadedDevicePropertiesSelector from './components/LoadedDevicePropertiesSelector';

export default {
  name: 'LoadedDeviceCreator',
  components: {
    RegionSelectModal,
    vueJsonEditor,
    UploadProgress,
    LoadedDevicePropertiesSelector,
    // LoadedDevicePropertyCard,
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      propertySelect: null,
      rules: {
        loadedDeviceTypeId: [{ required: true, message: '负载设备类型id 不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '负载设备类型名称 不能为空', trigger: 'blur' }],
      },
      progress: 1,
      form: {
        regionIdentifiers: [],
        propertyIds: [],
        properties: [],
        images: [
          {
            type: this.$constRepository.loadedDevice.ImageTypes.AIHOME,
            solidImages: [],
            lineImages: [],
          },
          {
            type: this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE,
            solidImages: [],
            lineImages: [],
          },
          {
            type: this.$constRepository.loadedDevice.ImageTypes.NEXSMART,
            solidImages: [],
            lineImages: [],
          },
        ],
      },
      tabList: [
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.AIHOME],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE
          ],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.NEXSMART}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.NEXSMART
          ],
        },
      ],
      tabKey: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    removeSolidImages(record) {
      // 找到是哪个卡片的图片需要删除
      const targetIndex = this.form.images.findIndex((image) => image.solidImages.find((simg) => simg.uid === record.uid));
      this.form.images[targetIndex].solidImages = this.form.images[targetIndex].solidImages.filter(
        (image) => image.uid !== record.uid,
      );
    },
    removeLineImages(record) {
      const targetIndex = this.form.images.findIndex((image) => image.lineImages.find((simg) => simg.uid === record.uid));
      this.form.images[targetIndex].lineImages = this.form.images[targetIndex].lineImages.filter(
        (image) => image.uid !== record.uid,
      );
    },
    async customRequest(request, module, submodule, imageFile) {
      this.$refs.uploadProgress.visible = true;
      const fileUploadResponse = await this.$apiManager.file.uploadFile(module, submodule, request.file);
      this.$refs.uploadProgress.visible = false;
      this.$store.commit('setUploadProgress', -1);
      if (fileUploadResponse.data.filePath) {
        const reader = new FileReader();
        reader.readAsDataURL(request.file);
        const params = {
          filePath: fileUploadResponse.data.filePath,
          uid: request.file.uid,
          status: 'done',
          name: request.file.name,
        };
        reader.onloadend = (e) => {
          const url = e.target.result;
          params.url = url;
          imageFile.push(params);
        };
      }
    },
    solidCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.loadedDeviceType,
        this.$constRepository.file.Submodule.solidImages,
        request.data.solidImages,
      );
    },
    lineCustomRequest(request) {
      this.customRequest(
        request,
        this.$constRepository.file.Module.loadedDeviceType,
        this.$constRepository.file.Submodule.lineImages,
        request.data.lineImages,
      );
    },
    onSubmit() {
      // 校验有图片的情况下，项目是否未选择
      if (this.form.images.length > 0) {
        let flag = true;
        this.form.images.forEach((image) => {
          if (!image.type) {
            flag = false;
          }
        });
        if (!flag) {
          this.$message.warn('请选择图片所属项目');
          return;
        }
      }
      const images = this.form.images.map((image) => ({
        ...image,
        solidImages: image.solidImages.map((item) => item.filePath),
        lineImages: image.lineImages.map((item) => item.filePath),
      }));
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.loadedDeviceType.createLoadedDeviceType({ ...this.form, images }).then(() => {
            this.$message.success('创建成功');
            this.$router.back();
          });
        }
      });
    },
    handlePropertySelect() {
      const toDO = (select, selectShow) => {
        this.form.propertyIds = select;
        this.form.properties = selectShow;
      };
      this.$refs.loadedDevicePropertiesSelector.selectType = 'checkbox';
      this.$refs.loadedDevicePropertiesSelector.loadedDeviceTypeId = this.form.loadedDeviceTypeId;
      this.$refs.loadedDevicePropertiesSelector.showCreateDialog(toDO);
    },
    handleRegionSelect() {
      const toDO = (select) => {
        this.form.regionIdentifiers = select;
      };
      this.$refs.regionSelectModal.showCreateDialog(toDO);
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  padding-top: 30px;
  width: 80%;

  /deep/ .ant-form-item-label {
    width: 150px;
  }
}

/deep/.jsoneditor-poweredBy {
  display: none;
}

// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}

.group-btn {
  margin-left: 16.7%;
}

.w-1\/3 {
  width: 33.33333333%;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}
</style>
